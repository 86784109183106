.amount-input {
    text-align: left;
    font-size: 14px;
}
.right-Sidebarse .step-Wrapse {
    height: 100%;
}
.scroll_div {
    overflow-y: auto;
    height: calc(100% - 100px);
    .login-Wrapse {
        border-radius: 0;
        box-shadow: none;
        .right-Logse {            
            background: none;
            padding-bottom:0;
        }
        .left-Logse > p {
            padding: 15px 0 0;
        }
        .binny-Mose {
            padding: 0;
        }
    }
}

.go-back {
    font-size: 14px;
    // display: inline-block;
    // margin-left:auto;
    // padding:10px 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 10px;
    img {
        width:18px
    }
}
.right-Menuse3{
    .scroll_div{
        padding:0;
    }
}
.approve-popup-screen {
    background: #e3ede2 !important;
}
.bankList-component-container, .bankList-container {
    height:100%;
}
.bankList-container {    
    text-align: right;    
}
.iframe-container {
    height:90%;
    iframe {
        border:0;
        width: 100%;
        height:600px;       
        .page {
            background-color: none !important;
        }
    }
}