.popup-content {
    width: 400px !important;
    border-radius: 8px;
    padding: 0;
    border: 0;
    position: fixed !important;
    top: 65px !important;
    @media (max-width: 991px) {
        left: auto !important;
        right: 10px;
       
    }
    @media (max-width: 520px) {
        width: 310px !important;
    }
    @media (max-width: 420px) {
        width: calc(100% - 20px) !important;
        & > div {
            svg {
                display: none;
            }
        }
    }
}
.notification-modal {
    min-width: 390px;
    @media (max-width: 520px) {
        min-width: 310px !important;
    }
    @media (max-width:767px){
        min-width: 300px !important;
    }
    @media (max-width: 420px) {
        min-width: calc(100% - 20px) !important;
        &.mid-Mainsec {
            left: 0px !important;
        }
    }
    .not_hdr {
        border-radius: 15px 15px 0 0;
        background-color: #fff;
    }
    .notification-head {
        border-radius: 15px 15px 0 0;
        padding: 5px 0;
        .clear_all {
            font-size: 12px;
            text-decoration: underline;
            text-align: right;
            color: #58aa55;
            cursor: pointer;
        }
    }
    .notification-body {
        .affiliateLink a:hover {
            color: #58aa55;
        }
    }
    .notification-list {
        background: #e3ede2;
        overflow-y: auto;
        overflow-x: hidden;
        width: auto;
        max-height: 410px;
        scrollbar-color: #555 rgba(0, 0, 0, 0.2) !important;
        scrollbar-width: thin !important;
        @media (max-width: 991px) {
            max-height: 270px;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #555;
        }
        .list-group-item {
            background: none;
            position: relative;
            display: block;
            padding: 15px;
            margin: 0;
            border-radius: 0px;
            border-left: 0;
            border-color: #fff;
            @media (max-width:767px){
                padding: 10px 15px;
            }
            &:first-child {
                border-top: 0;
            }
            &:last-child {
                &:before {
                    display: none;
                }
            }
            &.unread-msg {
                color: #71748d;
                margin-bottom: 1px;
                // padding-left: 30px;
                // &:after {
                //     content: "";
                //     background: #58aa55;
                //     position: absolute;
                //     width: 7px;
                //     height: 7px;
                //     left: 10px;
                //     top: calc(50% - 6px);
                //     border-radius: 20px;
                // }
            }
            &.unread-msg-list {
                &:after {
                    background: unset;
                }
            }
            .notification-list-user-block {
                font-size: 14px;
                line-height: 20px;
                .notifi_noimage {
                    // padding-left: 30px;
                    .notification-body {
                        a {
                            color: #015536;
                        }
                    }
                }
                .notification-list-user-name {
                    color: #58aa55;
                    font-size: 14px;
                    margin-right: 8px;
                }
                .notification-date {
                    display: block;
                    font-size: 11px;
                    color: #71748d;
                }
            }
        }
    }
    .img-pr {
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        min-height: 104px;
        width: 100%;
        @media (max-width: 767px) {
            /*margin-bottom: 10px;*/
            height: 70px;
            min-height: auto;
        }
        img {
            width: 100%;
        }
    }
    .list-footer {
        font-size: 14px;
        color: #fff;
        text-align: center;
        text-transform: capitalize;
        padding: 7px 0px;
        line-height: 1.5;
        font-weight: 700;
        background-color: #4c864a;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        a {
            color: #fff;
            font-weight: 500;
        }
    }
}
.notification_summ {
    margin-left: 10px;
    max-width: 70%;
    @media (max-width: 767px) {
        max-width: 100%;
        margin-left: 0px;
    }
}
.notification-body {
    white-space: normal;
    word-break: break-word;
}
.not_btns {
    .primary-Gbtn {
        border: 0;
        border-bottom: 1px solid #5cb629;
        font-size: 12px;
        line-height: 20px;
    }
    .primary-Gbtn,
    .orange-Btn {
        text-transform: uppercase;
        text-align: center;
        border-radius: 7px;
        padding: 10px 15px;
        font-weight: 700;
        font-size: 12px;
        margin-top: 7px;
        height: 40px;
        @media (max-width:767px){
            padding: 5px 15px;
            height: 31px;
        }
    }
    .orange-Btn {
        border-bottom: 1px solid #e3ad00;
    }
}
