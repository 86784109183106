.image-dive {width: 105px;}
.image-div-height img {margin: 0 !important;}
.image-height {height: 30px;}
.post-Title {line-height: 1.2;}
.post-Title img {margin:45px 0 0 0;}
.clock-img img {margin: 0 !important; height: auto;}
.app-Editsec img {margin: 0 !important;}
.app-Editsec ul { opacity: 1;}
.imgSec img {margin: 0 !important;}
.step-Wrapse ul.action_perform {padding: 0;}
.step-Wrapse ul.action_perform li {
    color: #363636;
    list-style-type: none;
    text-align: center;
}
.historic_page .historic_table .table_custom .list-Wrmainse td:last-child,
.historic_page .historic_table .table_custom .list-Wrmainse th:last-child {
    text-align: center;
}