.dyn_pages {
    padding: 40px 0;
    p {
        margin: 0 0 10px;
    }
}
.userpanel_view {
    ul {
        list-style: inherit;
        li {
            list-style: inherit;
        }
    }
    ol {
        li {
            list-style: inherit;
        }
    }
}

.customise-icon-container {
    cursor: pointer;
    text-align: left;
    float: left;
    color: #6c6c6e;
    background: none;
    border: 0;
    display: block;

    .customise-text {
        font-size: 14px;
    }
    .fa-edit {
        font-size: 16px;
        margin-left: 2px;
    }
}

.customise-icon-container:hover {
    color: #28d36c;
}
