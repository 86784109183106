.mid-Mainsec {
    @media (min-width:1280px){
    // min-height: calc(100vh - 88px - 522px);
    }
}
.news_details {
    h2,h3,h4,h5,h6 {margin-bottom: 15px;}
    .page_title {
        color:#58aa55;       
        word-break: break-word;
        white-space: normal;
    }
    p {margin-bottom: 15px;}
}