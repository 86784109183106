body{
    .toast{
        transform: translate(450px, 0);
        position: fixed;
        top: 20px;
        right: 20px;
        min-width: 200px;
        z-index: 99999999999999;
        transition: all ease-in-out 0.5s;
        -webkit-transition: all ease-in-out 0.5s;
        -moz-transition: all ease-in-out 0.5s;
        display: block !important;
    &.show{
        transform: translate(0px, 0);
    }
    .toast-body{
        opacity: 1;
        background-color: #fff;
    }
}
}