/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }
  
  /* iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }
  
  /* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
  @media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }
  
  /* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
  @media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
  }
  
  /* disable auto-zoom on iphone input field focus */
  @media screen and (-webkit-min-device-pixel-ratio:0) {
  
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    input {
      font-size: 16px;
    }
 }