.login_bnt {
  margin-right: 10px;
}
.register_btn {
  padding-left: 10px;
  padding-right: 10px;
}
.no-login {
  .login_bnt {
    @media (max-width: 767px) {
      margin-right: 0;
    }
  }
  .logo_title {
    width: 100%;
    img {
      position: relative;
      left: 0;
      text-align: center;
    }
  }
  .register_btn {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.close_nav {
  .tog_nav {
    background: #fff;
    position: fixed;
    top: 12px;
    left: 293px;
    cursor: pointer;
    z-index: 99999999;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);
    top: 18px;
    border: 1px solid #4c864a;
    @media (max-width: 575px) {
      left: 208px;
      top: 18px;
    }
    @media (max-width: 767px) {
      top: 18px;
    }
  }
}
.logo_title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  img {
    position: absolute;
    right: 0;
    left: 0;
    top: 5px;
    margin: auto;

    @media (max-width: 767px) {
      left: 100px;
      text-align: left;
      justify-content: start;
      top: 6px;
      margin: inherit;
      @media (max-width: 575px) {
        left: 50px;
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        top: 6px;
        width: 150px;
      }
    }
  }
}
.tog_title {
  display: block;
  text-align: center;
  width: 100%;
  position: absolute;
  min-height: 44px;
  top: 0;
  bottom: 0;
  word-break: break-word;
  white-space: normal;
  @media (max-width: 767px) {
    text-align: left;
    left: 0;
    top: 10px;
    right: 0;
    text-align: center;
    @media (max-width: 480px) {
      left: 0px;
      top: 7px;
      right: 0;
      text-align: center;
    }
  }

  span {
    font-size: 26px;
    color: #fff;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
.logo-title.tog_title.login {
  @media (max-width: 767px) {
    left: 60px;
    text-align: left;
    @media (max-width: 480px) {
      left: 40px;
    }
  }

  span {
    font-size: 26px;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 16px;
      font-weight: 600;
      @media (max-width: 480px) {
        font-size: 10px;
      }
    }
  }
}
.mobile_login_buttons {
  position: absolute;
  right: 0;
  top: 0;
  a {
    background: #4c864a;
    border-bottom: 2px solid #2c332c;
    border-right: 2px solid #2c332c;
    color: #fff;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    position: relative;
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    width: 34px;
    padding: 7px;
    transition: all 400ms ease-in-out 0s;
    @media (max-width: 575px) {
      width: 38px;
    }
    i {
      color: #ffdb30;
      font-size: 22px;
    }
  }
}
.css-1wa3eu0-placeholder {
  left: 0;
  right: 0;
  text-align: center;
}
@media (max-width: 480px) {
  .inner-Bnewse .primary-Gbtn.full-Width {
    padding: 11px 10px;
    font-size: 13px;
  }
  .load-Morese a.primary-Gbtn.large-Btn {
    /*padding: 11px 10px;
    font-size: 13px;
    width: 230px;*/
  }
}
@media (max-width: 400px) {
  .login .logo_title img {
    left: 40px !important;
    top: 9px !important;
    width: 110px !important;
  }
  @media (max-width: 360px) {
    .login .logo_title img {
      top: 13px !important;
      width: 70px !important;
    }
  }
}
