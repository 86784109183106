.modal-content {
  background: none;
  border: 0;
  box-shadow: none;
}
label.error-txt {
  font-size: 12px;
  color: red;
  text-align: left;
}
.bottom-line-container {
  padding: 0 30px 40px;
  text-align: center;
  position: absolute;
  bottom: 0;
  font-size: 12px;
}

@media (max-width: 991px) {
  .bottom-line-container {
    position: unset;
  }
}
