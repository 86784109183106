.news-Wrap {
    .news-Topimg {
        img {
            min-height: 150px;
            object-fit: cover;
            max-height: 200px;
            object-position: top;
            border: 1px solid #f3f3f3;
            display: block;
        }
    }
    .box-Centersec {
        h3 {
            word-break: break-word;
            white-space: normal;
        }
    }
    .news-Boxtxt {
        p {
            word-break: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            white-space: normal;
        }
    }
}
