.star-filled:before {
        content: "\f005" !important;
}

.star-empty:before {
    content: "\f006" !important;
}

.star-half-filled::before {
    content: "\f089 ";
}

.bonusPromoTitle:hover {
    color: #58aa55;
}

/*---------- star rating ----------*/
%flex-display {
    display: flex;
}
.star-rating {
    @extend %flex-display;
    align-items: center;
    font-size: 14px;
    justify-content: center;
}
.back-stars {
    @extend %flex-display;
    color: #ffffff !important;
    position: relative;
    cursor: pointer;
}
.front-stars {
    @extend %flex-display;
    color: #ffffff;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: all .5s;
}

.front-stars .fa-star, .back-stars .fa-star-o {
    margin: 0px 2px;
}
