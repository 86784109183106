input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiFormControl-root {
  width: 100% !important;
}
.MuiFormControl-root legend{ max-width: 0px;}