.mid-Mainsec {
    .notification_filter {
        label {
            margin-bottom: 0;
        }
        .clear_all {
            font-size: 12px;
            text-decoration: underline;
            text-align: right;
            color: #58aa55;
            cursor: pointer;
        }
        .filter-options {
            .form-control {
                width: inherit;
                margin: 3px;
            }
            .primary-Gbtn {
                padding: 10px;
                margin: 3px;
            }
        }
        .notification-list {
            margin-top: 20px;
            height: inherit;
            max-height: 500px;
            &.notifi_detail {
                background-color: #f7f7f7;
                border: 1px solid #eee;
                padding: 8px;
                li {
                    background-color: #dcdcdc; 
                    padding: 15px;
                    border: 0;
                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                    .img-pr {
                        img { 
                            width: auto;
                            max-width: 140px;
                            max-height: 108px;
                        }
                    }
                    .unread-msg {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            .list-group-item {
                padding: 0;
            }
            .notification-list-user-block {
                font-size: 15px;
                .img-pr {
                    max-width: 200px;
                    min-height: 85px;
                    overflow: hidden;
                    border-radius: 0;
                    @media (max-width: 767px) {
                        height: auto;
                    }
                    img {
                        width: 100%;
                    }
                }
                .notification_summ {
                    @media (max-width: 767px) {
                        width: 100%;
                        padding: 0;
                    }
                    .notification-body {
                        transition: all ease-in-out 0.5s;
                        -webkit-transition: all ease-in-out 0.5s;
                        -moz-transition: all ease-in-out 0.5s;
                        word-break: break-all;
                        span {
                            font-size: 12px;
                            color: #ff0000;
                            transition: all ease-in-out 0.5s;
                            -webkit-transition: all ease-in-out 0.5s;
                            -moz-transition: all ease-in-out 0.5s;
                        }
                    }
                }
                .notification-detail {
                    margin-left: 0px;
                    padding: 0;
                    @media (max-width: 767px) {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}
.inbox-empty {
    p {
        font-size: 14px;
        text-transform: uppercase;
    }
}
