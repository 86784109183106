.modal-custom {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  //overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: -1;
  }
}
.modal-dialog.modal-Wrapse {
  position: relative;
  pointer-events: all;
  @media (min-width: 576px) {
    margin: 1.75rem auto;
  }
}
.close-Modse {
  position: relative;
}
.right-Mdwrapse {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4c864a;
  border-radius: 0 14px 14px 0;
}
.chk-Bxse .btn.active::after {
  border: 1px solid #4c864a;
  z-index: -1;
}
.chk-Bxse .btn::before,
.chk-Bxse .btn.active::after {
  content: "";
  background: #fff;
}
.disabled {
  pointer-events: none;
  background: #e2e2e2 !important;
  border: 0 !important;
}
.icon-disable {
  color: black !important;
}
.signup-form-items{
.gender_form{
  .MuiOutlinedInput-multiline{
    padding: 8px 0 0px 10px;
    background: #fff;
  } 
  .MuiSvgIcon-root{
    fill: #58aa55;
  }
}
}
