.working-Mainse{
   .inner-Pwayse{
        .slick-track{
          display: flex;
        }
        .slick-slide{            
            box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.095);
            @media(max-width:767px){              
                box-shadow: none;
            }
            @media(min-width:768px){                
                height: inherit;
                margin: 60px 8px 15px;
                background: #fff;
                border-radius: 3px;
                &>div{
                    height: 100%;
                }
                border: 1px solid #d8d8d8;
                .play-Rmain{
                    height: 100%;
                    border: 0;
                    box-shadow: none;
                    margin: 0px auto;
                }
            }        
        }  
    }
}
.css-1pahdxg-control{
    border-color: transparent !important;
    background: transparent !important;       
    box-shadow: none!important;
    outline: 0;   
    border: 0!important;
    height: 45px;
    &:focus{
       border-color: transparent !important;
       background: #e3ede2;
       border: 0;
       box-shadow: none;
       outline: 0;
    }
    &:hover{
        border-color: transparent !important;
        background: #e3ede2;
        box-shadow: none;
        border: 0;
        outline: 0;
    }
}