/**Footer**/
.footer {background:#015536; position:relative;}
.fooer_strip {width:100%; margin-top:40px; }
.footer_top {padding:80px 0;}
.f_logo_group h5{color:#fff; font-weight:400;}
.fooer_strip img{width:100%;}

.f_menu ul {display:flex; flex-direction:row; justify-content:center;}
.f_menu ul li {margin:0 11px 10px;}
.f_menu ul li a {color:#fff; font-size:16px; text-transform:uppercase;
	@media (min-width:1600px){
		.f_menu ul li a {font-size:18px;}
	}	
}
.f_menu ul li a:hover {color:#ffc913;}
.other_logos {margin-top:20px;}
.cp_menu {
	 li {display:inline-block; color:#fff; font-size:12px; vertical-align:middle; margin:0 10px;
		a {color:#fff; font-size:12px;}
	}
}

.footer_btm {background:#033a26; padding:10px;}
.copyrhted_policy {
	 li{
		display:inline-block; vertical-align:middle; color:#fff; font-size:12px;
		&:last-child:after {
			display:none;
		}
		a {color:#ffc913; font-size:12px;
			&:hover {text-decoration:none;}
		}
		&:after {
			content:'|';
			font-size:18px;
			color:#fff;
			display:inline-block;
			margin:0 10px;
		}
		i img {
			max-height: 22px;
		}
	}
}

.fot-Botsec ul > li.bor-Rno + li.midse {
	@media (max-width:820px){
		padding-left: 10px;
	}
	span {
		@media (max-width:820px){
			display: none;
		}
	}
}


@media (max-width:767px){
.f_menu ul li a {font-size:14px;}
}