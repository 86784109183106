.work_row .swiper-container {
  display: flex;
}
.work_col {
  background: #fff;
  min-width: 268px;
  max-width: 270px;
  margin-right: 14px;
  display: inline-block;
  padding: 25px;
  position: relative;
}
.no_items {
  position: absolute;
  top: -4px;
  left: -4px;
  width: 48px;
  height: 48px;
  border: 3px solid #e3ede2;
  text-align: center;
  line-height: 40px;
  color: #58aa55;
  font-size: 24px;
  font-weight: 700;
  background: #fff;
}
.work_col i img {
  height: 70px;
}
.work_col h5 {
  margin: 20px 0 7px;
  font-size: 22px;
}
.step-header-content{
  padding: 20px 0px 0px !important;
}
