/** Compare, choose and start playing **/
.tprib-Mainse {position: absolute; left: 15px; top: 0; width: 80px; min-height: 22px; }
.trust_bar {position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 9; background: #1eae50; color: #fff; padding: 5px; font-size: 11px; text-transform: uppercase;
font-weight: 400; line-height: 11px; display: flex; align-items: center; justify-content: center; border-radius: 0 0 5px 5px; box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.095);     text-shadow: 0 0px 1px #000; }
.cp_items {position: relative; padding-top: 7px; margin-top:20px; z-index: 2;}

.top-Listse { background: #ffff; border-radius: 3px; overflow: hidden; box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09); position: relative;}
.top-Listse>.row { flex-wrap:no-wrap;}
@media screen and (min-width: 991px) {
.top-Listse .col-lg-3 { flex: 0 0 20%;max-width: 100%;}	
.top-Listse .col-lg-9 { flex: 0 0 80%; max-width: 100%; position: relative;} 
}

.logo-Topse {padding: 15px; display: flex; align-items: center; justify-content: center; height: 100%; min-height: 120px; }
.top-Listxt {padding: 15px;
    @media (min-width:992px){
        margin-bottom: 42px;
    }
}
.top-Listxt, .top-Listxt .row {height: 100%;}
.bonus-Boxse > h3, .spin-Boxse > h3 { font-size: 16px; font-weight: 700; line-height: 17px; color: #11778d; } 
.text-area p {margin-bottom: 0;}
.bonus-Boxse > p, .spin-Boxse > p {
    font-size: 13px;
    color: #374a54;
    line-height: 13px;
    font-weight: 500;
}
.bonus-Boxse > p {padding-top: 5px;}
.rating .star { display: inline-block; margin: 0 1px; background: #2d739b; width: 28px; height: 28px; line-height: 27px; text-align: center; -webkit-border-radius: 100%; -moz-border-radius: 100%; -ms-border-radius: 100%; -o-border-radius: 100%; border-radius: 100%; vertical-align: middle; }
.ratin-Boxse{text-align:center;}
.ratin-Boxse .rating .star { background: none; width: auto; height: auto; line-height: 16px;}
.rating i {color: #fff; font-size: 14px; vertical-align: middle; }
.ratin-Boxse > ul > li > a, .ratin-Boxse .rating .star i {
    background: none;
    font-size: 16px;
    color: #ffdd67;
    line-height: 16px;
}
.ratin-Boxse > p {font-size: 13px; line-height: 13px; color: #374a54; }
.info-Boxse > ul > li { font-size: 13px; line-height: 18px; font-weight: 700; text-align: left; padding-left: 20px; position: relative; color: #333; }
.info-Boxse > ul > li::before {
    content: "\f058";
    position: absolute;
    font-family: fontawesome;
    left: 0;
    top: 0;
    color: #43b422;
}
.primary-btn{ background: #65c82d; color: #fff; font-size: 15px; line-height: 16px; font-weight: 700; text-transform: uppercase; padding:15px 10px; border-bottom: 2px solid #4ea932;
    position: relative; display: inline-block; width: 100%; max-width: 100%; z-index: 2; overflow: hidden; text-align: center; border-radius: 10px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    transition: all 400ms ease-in-out 0s;
}
.primary-btn span {display:block; font-size:11px; color:#fff; text-transform:unset; font-weight:400;}
.orange-Btn { background: #ffc913; border-bottom: 2px solid #e3ad00; color: #2c2c2c; border-radius:80px;}

.list-Topse {line-height: 12px; text-align:center;}
.list-Topse {border:0; background: transparent; font-size: 11px; color: #939393; text-transform: uppercase; line-height: 12px; font-weight: 400; display: inline-block;}
.list-Topse i {margin-left: 7px;}

.mature_more{background: #fcfcfc; padding: 10px 15px; box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);}
.mature_more > p {font-size: 10px; line-height: 13px; color: #2c2c2c; font-weight: 300;}
.bonus-Boxse > h3, .spin-Boxse > h3 { font-size: 16px; font-weight: 700; line-height: 17px; color: #11778d; text-align: center; margin:0;}
.spin-Boxse > h3, .spin-Boxse > p > span {color: #43b422; }
.bonus-Boxse > p, .spin-Boxse > p {font-size: 13px; color: #374a54; line-height: 13px; font-weight: 500; }
.spin-Boxse > p {text-align: center; padding-top:5px;}

/*Collapse*/
.inner-Bpyse {background: #f1f1f1; text-align: left; padding: 30px; box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.15); margin-top: 5px;}
.reason-Btxtse {padding: 15px 0;}
.inner-Bpyse .reason-Btxtse {padding-top: 0;}
.reason-Btxtse > h3 { font-size: 15px; padding-left: 25px; position: relative; line-height: 16px; font-weight: 400; margin:0; }
.reason-Btxtse > h3 > i {position:absolute; left:0; color: #58af55;}
.rdetail-Bodyse .row, .genric-Bwrapse .row {margin: 0 -5px;}
.rdetail-Bodyse .col-lg-7, .rdetail-Bodyse .col-lg-5, .genric-Bwrapse .col-7, .genric-Bwrapse .col-5 {padding: 0 5px;} 
.genric-Bwrapse { padding: 10px; background: #fff; box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);}
.genric-Bwrapse + .genric-Bwrapse {
    margin-top: 10px;
}
.genric-Binfose > p { font-size: 14px; font-weight: 400; line-height: 15px; color: #2c2c2c;}
.ryt-Grnse .genric-Binfose > p, .ryt-Grnse .genric-Binfose > p > strong { color: #58af55; font-size: 15px; font-weight: 500;}
.genric-Binfose > p > span {font-size: 15px; font-weight:700; line-height: 16px; color: #2c2c2c; }
.pyment-Boxse ul {display: flex; align-items: center; flex-wrap: wrap; margin: 0 -3px; }
.pyment-Boxse ul > li {padding: 0 5px; max-width: 45px; }
.app-Boxse > ul { display: flex; align-items: flex-start; flex-wrap: wrap; margin: 0 -5px;}
.app-Boxse > ul > li {padding: 0 5px;}
.app-Boxse > ul > li > a {font-size: 20px; color: #58af55;}
.spcae-Topse3 {padding-top: 10px;}
.inner-Bpyse > p {
    font-size: 12px;
    font-weight: 300;
    color: #575757;
    line-height: 14px;
    padding-top: 10px;
    text-align: center;
}
.list-Topse > a .show_Ar {margin-left: 10px; display: inline-block; }
.list-Topse > a.collapsed .show_Ar { transform: rotate(-180deg); -webkit-transform: rotate(-180deg);}
@media screen and (max-width: 991px) {
.info-Boxse {display: inline-block; padding: 10px 0;}
}
@media screen and (max-width: 560px) {
    .pyment-Boxse ul > li { max-width: 55px !important; }
}
.tprib-Mainse:before {
    content: '';
    position: absolute;
    background: #1eae50;
    width: 14px;
    height: 9px;
    left: -3px;
	top: 3px;
	transform:rotate(25deg);
	-webkit-transform: rotate(25deg);
    z-index: -1;    
}
.tprib-Mainse:after {
    content: '';
    position: absolute;
    background:#1eae50;
    width: 14px;
    height:9px;
    right:-3px;	
    top:3px;
	z-index:-1;
    transform:rotate(25deg);
	-webkit-transform: rotate(-25deg);    
}