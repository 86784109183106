.brand_lists {
    background: #f8f8f8;
    padding: 20px 30px 30px;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, .1);
    text-align: left;
    margin-top:8px;
    @media (max-width:600px){
        .col-lg-12{
            max-width:50%;
        }
        .col-4{
            max-width: 100%;
            flex: 100%;
            min-height: 100px;
            padding: 0 5px;
        }
    }
}
.brand-col {
    margin-top: 10px;
    word-break: break-word;
    white-space: normal;
  
    h3 {
        font-size: 15px;
        font-weight: 500;
        line-height: 16px;
        color: #2c2c2c;
        word-break: break-word;
    }
    p {
        font-size: 13px;
        color: #454545;
        padding-top: 3px;
        padding-bottom: 10px;
        @media (max-width:360px){
            font-size: 10px;
        }
    }
}
.review_des {
    padding: 0px 0;
    // background: #f8f8f8;
    margin:30px 0 0;
    p {
        margin: 0 0 .75rem;
    }
}
.pros_cons_sec {
    background: #e3ede2;
    padding:50px 0;
    h4 {
        font-size: 20px;
        font-weight: 600;
        color: #2c2c2c;
        padding-bottom: 15px;
        i {
            color: #58af55;
            margin-right: 7px;
        }
    }
    ul {
        li {
            font-size: 16px;
            line-height: 22px;
            margin-top: 5px;
            padding-left: 25px;
            position: relative;
            &::before {
                content: "\f00c ";
                position: absolute;
                left: 0;
                top: 0;
                font-family: fontawesome;
                color: #58af55;
                font-size: inherit;
            }
        }
    }
    .neg-pt {
        li {
            &::before {
                content: "\f00d " !important;
                color: #EE2737FF !important;
            }
        }
    }
}
#casino-detail{
    padding-bottom: 0;
    padding-top: 120px;
    @media (max-width: 767px) {
        padding-top: 100px;
    }
    .top-Listxt{
        margin-bottom: 27px;
        @media(max-width: 767px){
            margin-bottom: 0;
        }
    }
   .age-Breqsec{
    background: #fcfcfc;
    padding: 8px 15px;
    min-height: 35px;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.079) inset;
    display: flex;
    align-items: center;
    justify-content: center;
    ul{
        align-items: center;
    justify-content: center;
    font-style: italic;
    li{
        font-size: 11px;
    }
    }
   }
}
#stickyCasino{
    display: none;
    z-index: 1000;
    position: fixed;
    top: auto;
    bottom: 0;
    width: 100%;
    background-color: #f4f4f4;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #eaeaea;
    padding: 20px 0 15px;
    font-size: 12px;
    a {
        color: #4ca0db;
    }
    @media(max-width: 767px){
        display: block;
    }
    @media(max-width: 575px){
        font-size: 0.50rem;
    }
}