.css-yk16xz-control {
    background: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    height: 45px;
    &>div {
        height: 100%;
        padding: 0!important;        
    }
    div + div {
        @extend .css-1hb7zxy-IndicatorsContainer;
    }
}
.select_img{
    position: relative;
    display: flex;
    align-items: center;
    .casino-image {
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        margin-right:0;
        text-align: center;
        img {
            min-height: 28px;
            max-height: 40px;
            width: auto;
        }
    }        
    p {
        display: none;
        word-break: break-all;
        font-weight: 700;
        color: #2c2c2c;
    }
}
.casino-Selctmenu {
    .css-1uccc91-singleValue {
        max-width:100%;
        margin: 0;
    }
    .css-1hb7zxy-IndicatorsContainer{
        position: absolute;
        height:100%;
        right:0;
        left: 0;
        text-align: center;
        svg {
            path {
                fill:#fff;
            }
        }
    }
}
.react-select-dropdown-prefix__value-container>div {
    width: 100%;
}
.react-select-dropdown-prefix__value-container {
    height: 100%;
    padding: 0!important;
}
.react-select-dropdown-prefix__indicator {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 11;
    align-items: center;
}
.react-select-dropdown-prefix__indicators:hover{
    color:#fff !important;
}
.react-select-dropdown-prefix__indicator:hover {
    color: #fff !important;
}
.css-1uccc91-singleValue, .css-g1d714-ValueContainer {
    height:44px; margin-right: 0!important;
    line-height: 44px;
    padding:0!important;
}
.css-4ljt47-MenuList, .css-yt9ioa-option, .css-1n7v3ny-option { padding:0!important; }
.css-1n7v3ny-option, .css-yt9ioa-option {margin:0 0 5px; border-radius: 10px; box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09);}
.css-9gakcf-option {padding: 0 !important; box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.09); background-color: #B2D4FF!important; margin:0 0 5px;}
.css-26l3qy-menu{
    box-shadow:none!important;
    border-radius: 0!important;
    >div {
        padding:10px!important; 
        // box-shadow: 0 0 10px rgba(0, 0, 0 , .1)
    }
    .casino-image { margin-right:10px; width:auto;}
    .casino-image img {
        height:44px; max-height:44px; width:auto;          
    }
    .select_img {
        border-radius:10px;
        p {
            display: block;
            width: 100%;
            font-weight:700;
            text-align: left;
            
        }
        img {
            top:0px!important;
        }
        .casino-image {
            background: rgb(39, 40, 74);
            min-height: 80px;
            margin-right: 10px; 
            min-width:100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px 0 0 10px;
            img {
                max-width: 100%;
                margin: auto;
                width: 70px;
                min-width: 70px;
                min-height: initial;
                max-height: initial;
                height: auto;
            }
        }
    }
}
.input-Grupse {
    &.color-green {
        p {
            color: #58aa55;
            font-weight: 500;
        }
    }
}
.error {
    font-size: 12px;
}
.thx-message {
    p {
        font-size: 16px;
    }
}


.react-select-dropdown-prefix__option{
    .approved {
        position: absolute;
        top: -8px;
        right: 15%;
        width:85px;
        min-height: 22px;
        .approved_icon  {
            i{
            position: absolute;
            left: -8px;
            top: -4px;
            font-size: 20px;
            line-height: 20px;
            color: #1eae50;
            z-index: -1;
            transform: rotate(-45deg);
            }
            i.ryt-Se {
                left: auto;
                right: -8px;
                transform: rotate(45deg);
            }

        }
        .approved-text {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            background: #1eae50;
            color: #fff;
            padding: 5px;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 0 5px 5px;
            // box-shadow: 0 3px 3px 1px rgb(0 0 0 / 10%);
        }
    }
}
.react-select-dropdown-prefix__control--menu-is-open span, 
.react-select-dropdown-prefix__control--is-focused span, .react-select-dropdown-prefix__control--is-focused .approved_icon {display:none;}