.modal-content {
  background: none;
  border: 0;
  box-shadow: none;
}
label.error-txt {
  font-size: 12px;
  color: red;
  text-align: left;
}
