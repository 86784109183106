@-moz-keyframes spin {
 100% {
 -moz-transform: rotate(360deg);
}
}
@-webkit-keyframes spin {
 100% {
 -webkit-transform: rotate(360deg);
}
}
@keyframes spin {
 100% {
 -webkit-transform: rotate(360deg);
 transform:rotate(360deg);
}
}
@-webkit-keyframes moveright {
 from {
left:-80px;
transform: scale(0.4);
}
to {
	left:80px;
	transform: scale(1);
}
}
@-moz-keyframes moveright {
 from {
left:-80px;
transform: scale(0.4);
}
to {
	left:80px;
	transform: scale(1);
}
}
@-o-keyframes moveright {
 from {
left:-80px;
transform: scale(0.4);
}
to {
	left:80px;
	transform: scale(1);
}
}
@keyframes moveright {
 from {
left:-80px;
transform: scale(0.4);
}
to {
	left:80px;
	transform: scale(1);
}
}
@keyframes round {
  0%   {  left:0px; top:0px;}
  25%  {  left:100%; top:0px;}
  50%  {  left:100%; top:100%;}
  75%  {  left:0px; top:100%;}
  100% { left:0px; top:0px;}
}
@keyframes round1 { 
  0%  {  left:100%; top:0px;}
  25%  {  left:100%; top:100%;}
  50%  {  left:0px; top:100%;}
  75% { left:0px; top:0px;}
  100%   {  left:100%; top:0px;}
}
@keyframes round2 { 
 
  0%  {  left:100%; top:100%;}
  25%  {  left:0px; top:100%;}
  50% { left:0px; top:0px;}
  75%   {  left:100%; top:0px;}
   100%  {  left:100%; top:100%;}
}
