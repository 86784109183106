.news_items {background:#fff; border:1px solid #b8b7b7; padding:20px; width:360px !important; margin:30px 0 0 0;}
 .news_items h6 {color:#2d2d2c; margin:0;}
.news_items p {font-size:14px;}
.web_date {color:#aeaeae; font-size:12px; margin:10px 0;}
.news_items .btn {min-height:44px; width:100%;}
.slick-slider {
    margin:0 -10px;
}
.slick-slide {
    padding:10px;
}

.no-news-content {
    text-align: center;
}
.inner-Bnewse{
    .slick-track{
        display: flex;
        .slick-slide {
            height: inherit;
            &>div{
                height: 100%; 
                .bg_white {
                    height: 100%;
                    padding: 0 10px;  
                    .news-Wrap{
                        height: calc(100% - 30px);
                        padding-bottom: 80px;
                        position: relative;
                    }
                }
            }
        }
    }
    
}

.inner-Bnewse > div .bg_white .news-Wrap {
    height: calc(100% - 30px);
    padding-bottom: 80px;
    position: relative;
}
.news-Rmainse.pt-0 .newss {
    align-items: initial;
}