body {
    overflow-x: hidden;
    .container_width {
        width: 100%;
        max-width: 80%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        @media (max-width: 991px) {
            max-width: 100%;
        }
    }
}

.sub_header {
    background: #58aa55;
    min-height: 70px;
    position: relative;
    z-index: 2;
    header {
        .tog_nav {
            margin-top: 0;
        }
    }
}
.historic_page {
    h3 {
        font-size: 36px;
        padding-bottom: 10px;
        position: relative;
        font-weight: 600;
        color: #2c2c2c;
    }
    .filter-options {
        .row {
            @media (min-width: 586px) {
                flex-wrap: nowrap!important;
            }
            @media (max-width: 585px) {
                justify-content: center!important;
            }
        }
        .d-flex {
            @media (max-width: 540px) {
                flex-direction: column;
                justify-content: flex-end !important;
                align-items: inherit !important;
                text-align: left;
            }
        } 
        label {
            font-weight: 600;
            margin-bottom: 0;
            margin: 3px;
        }
        .form-control {
            width: inherit;
            margin-right: 5px;
            min-width: 120px;
            font-size: 14px;
            @media (max-width:585px){
                /*font-size: .80rem;*/
                margin-bottom: 10px;
                min-width:182px;
                margin-right: 0px;
            }
            &:nth-child(2){
                @media (max-width:585px){
                    margin-right: 0;
                }
            }
        }
        .primary-Gbtn {
            height: 34px;
            padding: 10px;
            font-size:14px;
            font-weight: 400;
            margin: 0;
            border-radius: 4px;
            word-break: break-all;
            @media (max-width:575px){
                font-size: .80rem;
                margin-top:5px;
            }
        }
    }
    .historic_table {
        .table_custom {
            overflow-x: auto;
            .list-Wrmainse {
                width: 100%;
                margin-top: 10px;
                @media (max-width: 991px) {
                    box-shadow: none;
                }
                thead {
                    font-size: 1rem;
                }
                tbody {
                    font-size: 0.8rem;
                }
                tr {
                    background-color: #fff;
                    border-bottom: 1px solid #ccc;
                    &:nth-child(even) {
                        background-color: #f5f5f5;
                    }
                }
                td,
                th {
                    padding: 15px 8px;
                    color: #2c2c2c;
                    word-break: break-all;
                    max-width: 100px;

                    .image-dive {
                        max-width: 150px;
                        width: 100%;
                        text-align: center;
                        margin: auto;
                        .image-height {
                            height: 90px;
                            @media (max-width: 991px) {
                                height: auto;
                            }
                        }
                    }
                }
                td {
                    font-size: 0.78rem;
                    @media (max-width: 991px) {
                        .image-dive {
                            margin-left: auto !important;
                            margin-right: 0 !important;
                        }
                    }
                }
            }
            th {
                h6 {
                    font-weight: 500;
                    font-size: 0.85rem;
                    white-space: normal;
                    word-break: keep-all;
                }
            }
        }
    }
}
.pagWrap {
    display: flex;
    background: transparent;
    align-items: center;
    margin-top: 10px;
    justify-content: flex-end;
    ul {
        display: flex;
        align-items: center;
        @media (max-width:767px){
            padding:10px 0!important;
            width:100%;
        }
        li {
            font-size: 12px; 
            margin: 0 5px;
            border-radius: 6px;
            background: #ffffff;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:first-child {
                margin-left: 0;
            }
            &.next-btn {
                background: #58aa55;
                color: #fff;
            }
            &.disabled {
                background: rgba(88, 170, 85, 66%);
                color: #ffffff;
            }
            a {
                padding:0 10px;
                height: 30px;
                line-height: 30px;
                min-width: 30px;
                color: #363636 !important;
                text-align: center;
            }
        }
    }
}

.historic_page .historic_table .table_custom .list-Wrmainse tr td:nth-child(2),
.historic_page .historic_table .table_custom .list-Wrmainse th:nth-child(2) {
    text-align: right;
}
.historic_page .historic_table .table_custom .list-Wrmainse tr td:nth-child(4) span,
.historic_page .historic_table .table_custom .list-Wrmainse th:nth-child(4) span {
    white-space: nowrap;
}
